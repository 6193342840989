import st from'./Home.module.css';
import circleText from '../../imgs/circleOfText.svg'
import { DownloadLink } from '../UI/DownloadLink/DownloadLink';
import Presentation from '../../assets/presentation.txt'
import { MainTitle } from '../UI/MainTitle/MainTitle';
import { AdvantageList } from '../AdvantageList/AdvantageList';
import { StackBlock } from '../StackBlock/StackBlock';

import { CasesBlock } from '../Cases/Cases';
import { BitrixBlock } from '../BitrixBlock/BitrixBlock';
import { ContactsBlock } from '../ContactsBlock/ContactsBlock';
import ScrollToTop from '../UI/ScrollToTop/ScrollToTop';


export const Home = () => {
  return (<>

    <section className={st.bannerWrap}>
      <ScrollToTop/>
      <div className="container">
        <div className={st.mainBlock}>
          <div className={st.mainContent}>
            <MainTitle />
          </div>
          <DownloadLink path={Presentation} text="Скачать презентацию" />
          <AdvantageList/>
          <img className={st.circleText} src={circleText} alt="Продакшен с именем" />
        </div>
      </div>
    </section>

    <div className="container">
      <StackBlock/>
    </div>


    <CasesBlock/>
    <BitrixBlock/>
    <ContactsBlock />
  </>
  )
}





