import { useEffect, useState } from "react";
import Logo  from "../UI/Logo/Logo"
import { Menu } from "../UI/Menu/Menu"
import st from'./Header.module.css';
import cn from "classnames";

export const Header = () => {
  const [isFixedMenu, setIsFixtedMenu] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', function(e) {
     
      if (window.innerWidth > 850) {
        window.pageYOffset >= 500 ? setIsFixtedMenu(true) : setIsFixtedMenu(false)
      } else {
        setIsFixtedMenu(false)
      }
    });
  }, [window.pageYOffset])

  return (
    <div className={cn({[st.headerWrap]: true, [st.headerFix]: isFixedMenu})}>
      <div className="container">
        <header>
            <Logo />
            <Menu/>
        </header>
      </div>
    </div>
      
  )
}
