import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import Logo from '../UI/Logo/Logo';
import { SocialGroup } from '../UI/SocialGroup/SocialGroup';
import st from'./Footer.module.css';

export const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className={st.footerWrap}>
          <div className={st.footerRow}>
            <div className={st.footerCol}>
              <div className={st.logo}>
                <Logo logo="imgs/logo-wh.svg" color="#FFFFFF"/>
              </div>
              <div className={st.socialGroup}>
                <SocialGroup  />
              </div>
            </div>
            <div  className={cn(st.footerCol, st.footerNavBlock)}>
              <div>
                <h5>О компании</h5>
                <ul>
                  <li className={st.footerNavItem}>
                    <NavLink to="/competency"><span data-hover="Компетенции">Компетенции</span></NavLink>
                  </li>
                  <li className={st.footerNavItem}>
                    <NavLink to="/cases"><span data-hover="Проекты">Проекты</span></NavLink>
                  </li>
                  <li className={st.footerNavItem}>
                    <NavLink to="/about-ivan"><span data-hover="Про Ивана">Про Ивана</span></NavLink>
                  </li>
                </ul>
              </div>
              <div>
                <h5>BitrixMarketplace</h5>
                <ul>
                  <li className={st.footerNavItem}>
                    <a href="https://www.onliner.by/"  target="_blank" rel="noopener noreferrer"><span data-hover="Onliner">Onliner</span></a>
                  </li> 
                  <li className={st.footerNavItem}>
                    <a href="https://1k.by/"><span data-hover="Экспорт на 1k.by" target="_blank" rel="noopener noreferrer">Экспорт на 1k.by</span></a>
                  </li>
                  <li className={st.footerNavItem}>
                    <a href="https://marketplace.1c-bitrix.ru/solutions/phpdevorg.cprop/" target="_blank" rel="noopener noreferrer"><span data-hover="Комплексное свойство">Комплексное свойство</span></a>
                  </li>
                </ul>
              </div>
              {/* <div>
                <a href="" className={st.orderDev}><span data-hover="Заказать разработку">Заказать разработку</span></a>
              </div> */}
            </div>
          </div>
          
          <div>
            <NavLink className={st.footerPolity} to="/polity">Политика конфиденциальности</NavLink>
          </div>
          
      </div>
      </div>
    </footer>
  )
}

