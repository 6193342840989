import { ContactsBlock } from "../ContactsBlock/ContactsBlock"
import ScrollToTop from "../UI/ScrollToTop/ScrollToTop"
import { AboutIvan } from "./AboutIvan/AboutIvan"

export const AboutPage = () => {
  return (
    <div>
      <ScrollToTop/>
      <AboutIvan />
      <ContactsBlock />
    </div>
  )
}

