import st from './Cases.module.css';
import './Cases.css';
import { useWindowSize } from '../../Hooks/useWindowSize';
import { useEffect, useState } from 'react';
import { StackTag } from '../StackBlock/StackBlock';
import { NavLink } from 'react-router-dom';
import aos from 'aos';
import 'aos/dist/aos.css'
import cn from 'classnames'

export const cases = [
  {
    id: 1,
    title: "Разработка и продвижение интернет-магазина дверей",
    description: "В частности, разбавленное изрядной долей эмпатии, рациональное мышление в значительной степени обусловливает ",
    tags: {
      'php' : ['PHP'],
      'js' : ['Vue.js'],
      'css' : ['CSS3'],
      'python' : ['Flask']
    },
    img: {
      src: "imgs/cases/case1.jpg",
      alt: "case1"
    },
    slider: [
      "../imgs/cases/case1.jpg",
      "../imgs/cases/case2.jpg",
      "../imgs/cases/case3.jpg",
      "../imgs/cases/case4.jpg",
    ],
    aims: [
      'Разработать удобный каталог товаров с возможностью заказа продукции',
      'Увеличить кол-во обращений в компанию в 6 раз',
      'Максимально подробно рассказать на сайте о товаре, чтобы покупатель занимал минимум времени менеджера при оформлении заказа',
      'Увеличить посещаемость сайта',
    ],
    results: [
      'Компания за 4 года стала лидером в своем регионе;',
      'Поисковый трафик на сайт вырос с 650 посетителей в месяц до 6800 в месяц;',
      'Количество звонков в компанию выросло в 5 раз;',
      'Канал контекстной рекламы привлекает в организацию ежемесячно 40% от общего количества обращений;',
      'Позиции сайта в ТОП-10 выросли с 51 запроса до 250 запросов.',
    ]
  },
  {
    id: 2,
    title: "Разработка интернет-магазина оборудования для бассейнов",
    description: "В частности, разбавленное изрядной долей эмпатии, рациональное мышление в значительной степени обусловливает ",
    tags: {
      'php' : ['PHP', 'dcscd'],
      'js' : ['Vue.js', 'React'],
      'css' : ['CSS3', 'SASS'],
      'python' : ['Flask', 'dscsdc','cdscds']
    },
    img: {
      src: "imgs/cases/case2.jpg",
      alt: "case2"
    },
    slider: [
      "../imgs/cases/case1.jpg",
      "../imgs/cases/case2.jpg",
      "../imgs/cases/case3.jpg",
      "../imgs/cases/case4.jpg",
    ],
    aims: [
      'Разработать удобный каталог товаров с возможностью заказа продукции',
      'Увеличить кол-во обращений в компанию в 6 раз',
      'Максимально подробно рассказать на сайте о товаре, чтобы покупатель занимал минимум времени менеджера при оформлении заказа',
      'Увеличить посещаемость сайта',
    ],
    results: [
      'Компания за 4 года стала лидером в своем регионе;',
      'Поисковый трафик на сайт вырос с 650 посетителей в месяц до 6800 в месяц;',
      'Количество звонков в компанию выросло в 5 раз;',
      'Канал контекстной рекламы привлекает в организацию ежемесячно 40% от общего количества обращений;',
      'Позиции сайта в ТОП-10 выросли с 51 запроса до 250 запросов.',
    ]
  },
  {
    id: 3,
    title: "Маркетплейс для банка Home Credit",
    description: "В частности, разбавленное изрядной долей эмпатии, рациональное мышление в значительной степени обусловливает ",
    tags: {
      'php' : ['PHP', 'dcscd'],
      'js' : ['Vue.js', 'React'],
      'css' : ['CSS3', 'SASS'],
      'python' : ['Flask', 'dscsdc','cdscds']
    },
    img: {
      src: "imgs/cases/case3.jpg",
      alt: "case3"
    },
    slider: [
      "../imgs/cases/case1.jpg",
      "../imgs/cases/case2.jpg",
      "../imgs/cases/case3.jpg",
      "../imgs/cases/case4.jpg",
    ],
    aims: [
      'Разработать удобный каталог товаров с возможностью заказа продукции',
      'Увеличить кол-во обращений в компанию в 6 раз',
      'Максимально подробно рассказать на сайте о товаре, чтобы покупатель занимал минимум времени менеджера при оформлении заказа',
      'Увеличить посещаемость сайта',
    ],
    results: [
      'Компания за 4 года стала лидером в своем регионе;',
      'Поисковый трафик на сайт вырос с 650 посетителей в месяц до 6800 в месяц;',
      'Количество звонков в компанию выросло в 5 раз;',
      'Канал контекстной рекламы привлекает в организацию ежемесячно 40% от общего количества обращений;',
      'Позиции сайта в ТОП-10 выросли с 51 запроса до 250 запросов.',
    ]
  },
  {
    id: 4,
    title: "Маркетплейс для банка Home Credit",
    description: "В частности, разбавленное изрядной долей эмпатии, рациональное мышление в значительной степени обусловливает ",
    tags: {
      'php' : ['PHP', 'dcscd'],
      'js' : ['Vue.js', 'React'],
      'css' : ['CSS3', 'SASS'],
      'python' : ['Flask', 'dscsdc','cdscds']
    },
    img: {
      src: "imgs/cases/case4.jpg",
      alt: "case4"
    },
    slider: [
      "../imgs/cases/case1.jpg",
      "../imgs/cases/case2.jpg",
      "../imgs/cases/case3.jpg",
      "../imgs/cases/case4.jpg",
    ],
    aims: [
      'Разработать удобный каталог товаров с возможностью заказа продукции',
      'Увеличить кол-во обращений в компанию в 6 раз',
      'Максимально подробно рассказать на сайте о товаре, чтобы покупатель занимал минимум времени менеджера при оформлении заказа',
      'Увеличить посещаемость сайта',
    ],
    results: [
      'Компания за 4 года стала лидером в своем регионе;',
      'Поисковый трафик на сайт вырос с 650 посетителей в месяц до 6800 в месяц;',
      'Количество звонков в компанию выросло в 5 раз;',
      'Канал контекстной рекламы привлекает в организацию ежемесячно 40% от общего количества обращений;',
      'Позиции сайта в ТОП-10 выросли с 51 запроса до 250 запросов.',
    ]
  },
  {
    id: 5,
    title: "Маркетплейс для банка Home Credit",
    description: "В частности, разбавленное изрядной долей эмпатии, рациональное мышление в значительной степени обусловливает ",
    tags: {
      'php' : ['PHP', 'dcscd'],
      'js' : ['Vue.js', 'React'],
      'css' : ['CSS3', 'SASS'],
      'python' : ['Flask', 'dscsdc','cdscds']
    },
    img: {
      src: "imgs/cases/case5.jpg",
      alt: "case5"
    },
    slider: [
      "../imgs/cases/case1.jpg",
      "../imgs/cases/case2.jpg",
      "../imgs/cases/case3.jpg",
      "../imgs/cases/case4.jpg",
    ],
    aims: [
      'Разработать удобный каталог товаров с возможностью заказа продукции',
      'Увеличить кол-во обращений в компанию в 6 раз',
      'Максимально подробно рассказать на сайте о товаре, чтобы покупатель занимал минимум времени менеджера при оформлении заказа',
      'Увеличить посещаемость сайта',
    ],
    results: [
      'Компания за 4 года стала лидером в своем регионе;',
      'Поисковый трафик на сайт вырос с 650 посетителей в месяц до 6800 в месяц;',
      'Количество звонков в компанию выросло в 5 раз;',
      'Канал контекстной рекламы привлекает в организацию ежемесячно 40% от общего количества обращений;',
      'Позиции сайта в ТОП-10 выросли с 51 запроса до 250 запросов.',
    ]
  },
]

export const CasesBlock = () => {
  return (
    <section className={st.casesBlock}>
      <div className="container">
        <h2>Проекты</h2>
        <Cases />

        <NavLink className={st.moreCases} to="/cases">Все работы</NavLink>
      </div>
    </section>
  )
}

export const Cases = () => {


  return (
    <div className={st.casesList}>
      {
        cases.map(item => <Case key={item.id} data={item} />)
      }
    </div>
  )
}


export const Case = (props) => {
  const {id, title, description, tags, img} = props.data;
  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(true);

  let stackTagsElements= [];

  Object.keys(tags).forEach(function(key) {
    stackTagsElements = stackTagsElements.concat(this[key].map((tag, index) => <StackTag key={index+tag} category={key}  name={tag} anim="flip-up" delay={index * 100}/>))
  }, tags);



  useEffect(() => {
    size.width > 768 ? setIsMobile(false) : setIsMobile(true)
  }, [size]);

  useEffect(() => {
    aos.init({
      duration: 600,
      easing: 'ease-in-out',
      useClassNames: true,
      initClassName: false,
      animatedClassName: 'animated'
    }); 
  }, [])

  return (
    <div  className={st.caseItem}>
      
      <div className={st.caseItem__content}>
        <h3>{title}</h3>
        <p>{description}</p>

        <div data-aos="fade-up" className={cn({[st.caseItem__wrapImgMob]: true})}>
          <img src={img.src} alt={img.alt}/>
        </div>

        <div className={st.caseItem__tags}>
          {
            stackTagsElements
          }
        </div>
        <span className={st.caseItem__more}>
          <NavLink to={{pathname: "cases/" + id}} >Смотреть кейс</NavLink>
        </span>
      </div>
      <div>
        <div data-aos="fade-up" className={cn({[st.caseItem__wrapImgDesk]: true, [st.caseItem__imgAnim]: true})}>
          <img src={img.src} alt={img.alt}/>
        </div>
      </div>
    </div>
  )
}