import st from'./MainTitle.module.css';
import Typist from "react-typist"

const cursorStyle = {
  blink: true, 
  hideWhenDone: true,
  element: '_'
}

export const MainTitle = () => {
  return (
    <h1 className={st.mainTitle}>
      <Typist cursor={cursorStyle} loop>
        Аутсорсинг и
        <Typist.Backspace count={8} delay={200} />таффинг и аууу &#128058;
        <Typist.Backspace count={5} delay={200} />тсорсинг IT-специалистов
      </Typist>
    </h1>
  )
}