// import { StackTag } from '../UI/StackTag/StackTag';
import st from'./StackBlock.module.css';
import aos from 'aos';
import 'aos/dist/aos.css'
import { useEffect } from 'react';

export const StackBlock = () => {
  return (
    <section className={st.stackBlock} id="competency">
      <h2>Стек технологий</h2>
      <StackList />
    </section>
  )
}

const data = {
  category: [
    {"PHP": ['CRM Битрикс24', 'Yii2', '1С-Битрикс', 'PHP', 'Wordpress', 'Symfony', 'Opencart', 'Laravel']},
    { "JS": ['Angular', 'Node.js', 'React', 'Vue.js']},
    {"CSS": [ 'CSS3', 'SASS', 'LESS', 'Opencart']},
    { "Python": [ 'Django', 'unittest', 'Flask', 'nose2', 'pytest']}
  ]
}

export const StackList = () => {
  useEffect(() => {
    aos.init({
      duration: 600,
      easing: 'ease-in-out',
    }); 
  }, [])
  return (
    <div className={st.stackList}>
      {
        data.category.map((item, index) =>  <StackItem key={index} delay={index * 100} title={Object.keys(item)[0]} tags={Object.values(item)[0]}/>)
      }
   </div>
  )
}

export const StackItem = (props) => {
  
  return (
   <div data-aos="fade-up" data-aos-delay={props.delay} className={st.stackItem}>
     <div className={st.stackItem__header}><p>{props.title}</p></div>
     <div className={st.stackItem__body}>
       {
         props.tags.map((tag, index) =>  <StackTag key={index} category={props.title} name={tag} anim="flip-down" delay={props.delay + 400}/>)
       }
     </div>
   </div>
  )
}

export const StackTag = (props) => {
  return (
    <span data-aos={props.anim} 
          data-aos-delay={props.delay}
          data-category={props.category}
          className={st.stackItem__tag}>{props.name}</span>
  )
}