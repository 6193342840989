import st from'./ContactForm.module.css';
import { Formik } from 'formik';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import { useIMask } from 'react-imask';

function InputPhoneWithMask (props) {
  const [ opts, setOpts ] = useState({ mask: '+{7}(000)000-00-00' });
  const { ref, maskRef } = useIMask(opts);

  const handleBlur = () => {
    if (!maskRef.current.value) {
      props.setTouchedPhone({isTouched: true, error: "Введите номер телефона"});
      props.setPhone("");
    } else if (maskRef.current.value.length !== 16) {
      props.setTouchedPhone({isTouched: true, error: "Номер некорректный"})
      props.setPhone("");
    } else {
      props.setTouchedPhone({isTouched: false, error: ""})
      props.setPhone(maskRef.current.value)
    }
  }

  return (
    <input 
      ref={ref} 
      type="text" 
      name="phone" 
      placeholder="Телефон"
      // onChange={props.handleChange}
      onBlur={handleBlur}
      // value={props.values?.phone}
    />
  );
}

export const ContactForm = (props) => {

  const [nameFile, setNameFile] = useState("Прикрепить файл");
  const [touchedPhone, setTouchedPhone] = useState({isTouched: false, error: "Введите номер телефона"});
  const [phoneNumber, setPhone] = useState("");


  const onFileSelected = (e) => {
    if (e.target.files && e.target.files.length) {
      setNameFile(e.target.files[0].name);
      const taskFile = e.target.files[0];
      const fileData = new FormData();
      fileData.append("task", taskFile);
    }
  }

  return (
   <div className={st.сontactsForm}>
     <h4>Хотите провести собеседование со специалистами?</h4>
     
     <Formik
       initialValues={{ name: '', phone: '', task: '', file: '' }} //file: null??
       validate={values => {
         const errors = {};
         if (!values.name) {
           errors.name = 'Имя обязательно для заполнения';
         } else if (values.name.length < 2) {
           errors.name = 'Имя должно содержать минимум 2 буквы';
         }

         if (!phoneNumber) {
          errors.phone = touchedPhone.error;
          } 
        //  if (!values.phone) {
        //   errors.phone = 'Заполните номер телефона';
        // } 
         return errors;
       }}

       onSubmit={(values, { setSubmitting }) => {
        values = {...values, phone: phoneNumber}
        alert("Данные успешно отправлены!")
         setTimeout(() => {
           alert(JSON.stringify(values, null, 2));
           setSubmitting(false);
         }, 400);
       }}
     >
       {({
         values,
         errors,
         touched,
         handleChange,
         handleBlur,
         handleSubmit,
         isSubmitting,
         /* and other goodies */
       }) => (

         <form onSubmit={handleSubmit}>
            <div className={st.сontactsPlaceholderWrap}>
              <input
                type="text"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                placeholder="Имя"
              />
              <span className={st.сontactsPlaceholder}>Имя</span>
              <span className={st.сontactsFormError}>{errors.name && touched.name && errors.name}</span>
            </div>

            {/* <div className={st.сontactsPlaceholderWrap}>
              <input
                type="text"
                name="phone"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                placeholder="Телефон"
              />
              <span className={st.сontactsPlaceholder}>Телефон</span>
              <span className={st.сontactsFormError}>{errors.phone && touched.phone && errors.phone}</span>
            </div> */}

            <div className={st.сontactsPlaceholderWrap}>
              <InputPhoneWithMask onAccept={handleChange} onBlur={handleBlur} value={values.phone} setTouchedPhone={setTouchedPhone} setPhone={setPhone}/>
              <span className={st.сontactsPlaceholder}>Телефон</span>
              <span className={st.сontactsFormError}>{ touched.phone && touchedPhone.error }</span>
            </div>

          <div className={st.сontactsPlaceholderWrap}>
            <textarea
              name="task"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.task}
              placeholder="Коротко о задаче"
            />
            <span className={st.сontactsPlaceholder}>Коротко о задаче</span>
            <span className={st.сontactsFormError}>{errors.task && touched.task && errors.task}</span>
          </div>

            <label htmlFor="file">{nameFile}</label>
           <input
             type="file"
             name="file"
             id="file"
             onChange={onFileSelected}
             onBlur={handleBlur}
             value={values.file }
           />
            <span className={st.сontactsFormError}>{errors.file  && touched.file  && errors.file }</span>

           <button className={st.сontactsFormButton} type="submit" disabled={isSubmitting}>Отправить</button>
         </form>
       )}
     </Formik>

     <span className={st.сontactsAgreementPolity }>
        Отправка сообщение означает согласие на обработку персональных данных согласно {' '}
        <NavLink className={st.footerPolity} to="/polity">Политике конфиденциальности</NavLink>
     </span>
   </div>
  )
}