import { Route, Switch } from "react-router-dom"
import { AboutPage } from "../AboutPage/AboutPage"
import { Case1 } from "../Cases/Case/Case1/Case1"
import { CasesPage } from "../CasesPage/CasesPage"
import { Home } from "../Home/Home"
import { NotFound } from "../UI/NotFound/NotFound"
import { Polity } from "../UI/Polity/Polity"

export const Main = () => {
  return (
    <main>
      <Switch>
        <Route exact path="/">
            <Home />
        </Route>

        <Route exact path="/cases">
            <CasesPage />
        </Route>

        <Route exact path="/cases/:caseId?">
          <Case1/>
        </Route>

      


        <Route exact path="/about-ivan">
          <AboutPage />
        </Route>

        <Route exact path="/polity">
          <Polity />
        </Route>

          <Route component={NotFound} />
          {/* <Route path='*'>
            <NotFound />
          </Route> */}
      </Switch>
    </main>
  )
}


