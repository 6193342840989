import { NavLink, withRouter } from 'react-router-dom'
import st from './Logo.module.css'
import 'aos/dist/aos.css';



const Logo = (props) => {
  return ( 
    <NavLink to="/" onClick={props.closeMobileMenu}>
      <div className={st.logoWrap}>
        <svg  viewBox="0 0 89 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.988281 25.2H6.82028V0H0.988281V25.2Z" fill={props.color ? props.color : "black"}/>
          <path d="M31.0922 0L23.3162 18L15.6482 0H9.34816L20.2202 25.2H25.9802L36.8882 0H31.0922Z" fill={props.color ? props.color : "black"}/>
          <path d="M56.9764 25.2H63.0964L51.8284 0H46.0684L34.8364 25.2H40.8124L43.0444 19.8H54.7444L56.9764 25.2ZM44.9164 15.372L48.9124 5.724L52.9084 15.372H44.9164Z" fill={props.color ? props.color : "black"}/>
          <path d="M83.0629 0V15.3L70.5349 0H65.7109V25.2H71.4709V9.9L84.0349 25.2H88.8229V0H83.0629Z" fill={props.color ? props.color : "black"}/>
        </svg>
        {/* <span data-aos="fade-down" data-aos-delay="2000" className={st.logoDot}></span> */}
        <div className={st.logoDot}></div>
      </div>
    </NavLink>
  )
}


export default withRouter(Logo)