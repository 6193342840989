import st from'./CasesPage.module.css';
import { Cases } from "../Cases/Cases"
import { ContactsBlock } from '../ContactsBlock/ContactsBlock';
import ScrollToTop from '../UI/ScrollToTop/ScrollToTop';


export const CasesPage = () => {
  return (
    <section className={st.casesPage}>
      <ScrollToTop/>
      <div className="container">
        <h2>Проекты</h2>
        <Cases/>
        <ContactsBlock />
      </div>
    </section>
  )
}