import ScrollToTop from "../ScrollToTop/ScrollToTop";
import st from'./Polity.module.css';


export const Polity = () => {
  return (
    <section className={st.polityBlock}>
      <ScrollToTop/>
      <div className="container">
        <h1>Политика в отношении обработки персональных данных в «IVAN.»</h1>
        <ol>
          <li><h2>Общие положения</h2>
              <ol>
                <li>Политика в отношении обработки персональных данных (далее — Политика) направлена на защиту прав и свобод физических лиц, персональные данные которых обрабатывает ИП Раневская В. С. (далее — Оператор).</li>
                <li>Политика разработана в соответствии с п. 2 ч. 1 ст. 18.1 Федерального закона от 27 июля 2006 г. № 152-ФЗ «О персональных данных» (далее — ФЗ «О персональных данных»).</li>
                <li>Политика содержит сведения, подлежащие раскрытию в соответствии с ч. 1 ст. 14 ФЗ «О персональных данных», и является общедоступным документом.</li>
              </ol>
          </li>
          
          <li><h2>Сведения об операторе</h2>
              <ol>
                <li>Оператор ведет свою деятельность по адресу Краснодарский край, г. Краснодар, Российская 30, 350000.</li>
                <li>Руководитель Раневская Виктория Сергеевна  назначен ответственным за организацию обработки персональных данных.</li>
                <li>База данных информации, содержащей персональные данные граждан Российской Федерации, находится по адресу: Российская 30, Краснодар.</li>
              </ol>
          </li>

          <li><h2>Сведения об обработке персональных данных</h2>
              <ol>
                <li>Оператор обрабатывает персональные данные на законной и справедливой основе для выполнения возложенных законодательством функций, полномочий и обязанностей, осуществления прав и законных интересов Оператора, работников Оператора и третьих лиц.</li>
                <li>Оператор получает персональные данные непосредственно у субъектов персональных данных.</li>
                <li>Оператор обрабатывает персональные данные автоматизированным и неавтоматизированным способами, с использованием средств вычислительной техники и без использования таких средств.</li>
                <li>Действия по обработке персональных данных включают сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление и уничтожение.</li>
                <li>Базы данных информации, содержащей персональные данные граждан Российской Федерации, находятся на территории Российской Федерации.</li>
              </ol>
          </li>

          <li><h2>Обработка персональных данных работников</h2>
              <ol>
                <li>Оператор обрабатывает персональные данные работников Оператора в рамках правоотношений, урегулированных Трудовым Кодексом Российской Федерации от 30 декабря 2001 г. № 197-ФЗ (далее — ТК РФ), в том числе главой 14 ТК РФ, касающейся защиты персональных данных работников.</li>
                <li>Оператор обрабатывает персональные данные работников с целью выполнения трудовых договоров, соблюдения норм законодательства РФ, а также с целью:
                  <ul>
                    <li>вести кадровый учёт;</li>
                    <li>вести бухгалтерский учёт;</li>
                    <li>осуществлять функции, полномочия и обязанности, возложенные законодательством РФ на Оператора, в том числе по предоставлению персональных данных в органы государственной власти, в Пенсионный фонд РФ, в Фонд социального страхования РФ, в Федеральный фонд обязательного медицинского страхования, а также в иные государственные органы;</li>
                    <li>соблюдать нормы и требования по охране труда и обеспечения личной безопасности работников ИП Раневская В. С., сохранности имущества;</li>
                    <li>контролировать количество и качество выполняемой работы;</li>
                    <li>предоставлять льготы и компенсации, предусмотренные законодательством РФ.</li>
                  </ul>
                </li>
                <li>Оператор не принимает решения, затрагивающие интересы работников, основываясь на их персональных данных, полученных электронным образом или исключительно в результате автоматизированной обработки.</li>
                <li>Оператор защищает персональные данные работников за счет собственных средств в порядке, установленном ТК РФ, ФЗ «О персональных данных» и иными федеральными законами.</li>
                <li>Оператор знакомит работников и их представителей под роспись с документами, устанавливающими порядок обработки персональных данных работников, а также об их правах и обязанностях в этой области.</li>
                <li>Оператор разрешает доступ к персональным данным работников только допущенным лицам, которые имеют право получать только те данные, которые необходимы для выполнения их функций.</li>
                <li>Оператор получает все персональные данные работников у них самих. Если данные работника возможно получить только у третьей стороны, Оператор заранее уведомляет об этом работника и получает его письменное согласие. Оператор сообщает работнику о целях, источниках, способах получения, а также о характере подлежащих получению данных и последствиях отказа работника дать письменное согласие на их получение.</li>
                <li>Оператор обрабатывает персональные данные работников с их письменного согласия, предоставляемого на срок действия трудового договора.</li>
                <li>Оператор обрабатывает персональные данные работников в течение срока действия трудового договора. Оператор обрабатывает персональные данные уволенных работников в течение срока, установленного п. 5 ч. 3 ст. 24 части первой Налогового Кодекса Российской Федерации от 31 июля 1998 г. № 146-ФЗ, ч. 1 ст. 29 Федерального закона «О бухгалтерском учёте» от 6 декабря 2011 г. № 402-ФЗ и иными нормативными правовыми актами.</li>
                <li>Оператор может обрабатывать специальные категории персональных данных работников (сведений о состоянии здоровья, относящихся к вопросу о возможности выполнения ими трудовых функций) на основании п. 2.3 ч. 2 ст. 10 ФЗ «О персональных данных».</li>
                <li>Оператор не обрабатывает биометрические персональные данные работников.</li>
                <li>Оператор не получает данные о членстве работников в общественных объединениях или их профсоюзной деятельности, за исключением случаев, предусмотренных ТК РФ или иными федеральными законами.</li>
                <li>Оператор обрабатывает следующие персональные данные работников:
                  <ul>
                    <li>Фамилия, имя, отчество;</li>
                    <li>Тип, серия и номер документа, удостоверяющего личность;</li>
                    <li>Дата выдачи документа, удостоверяющего личность, и информация о выдавшем его органе;</li>
                    <li>Адрес;</li>
                    <li>Адрес электронной почты;</li>
                    <li>Доходы;</li>
                    <li>Должность;</li>
                    <li>Год рождения;</li>
                    <li>Месяц рождения;</li>
                    <li>Дата рождения;</li>
                    <li>Место рождения;</li>
                    <li>Идентификационный номер налогоплательщика;</li>
                    <li>Номер страхового свидетельства государственного пенсионного страхования;</li>
                    <li>Национальная принадлежность;</li>
                    <li>Профессия;</li>
                    <li>Страховые взносы на ОПС;</li>
                    <li>Страховые взносы на ОМС;</li>
                    <li>Налоговые вычеты;</li>
                    <li>Выход на пенсию;</li>
                    <li>Табельный номер;</li>
                    <li>Трудовой стаж.</li>
                  </ul>
                </li>
                <li>Оператор не сообщает третьей стороне персональные данные работника без его письменного согласия, кроме случаев, когда это необходимо для предупреждения угрозы жизни и здоровью работника, а также в других случаях, предусмотренных ТК РФ, ФЗ «О персональных данных» или иными федеральными законами.</li>
                <li>Оператор не сообщает персональные данные работника в коммерческих целях без его письменного согласия.</li>
                <li>Оператор передаёт персональные данные работников их представителям в порядке, установленном ТК РФ, ФЗ «О персональных данных» и иными федеральными законами, и ограничивает эту информацию только теми данными, которые необходимы для выполнения представителями их функций.</li>
                <li>Оператор предупреждает лиц, получающих персональные данные работника, что эти данные могут быть использованы только в целях, для которых они сообщены, требует от этих лиц подтверждения, что это правило соблюдено.</li>
                <li>Работник может получить свободный бесплатный доступ к информации о его персональных данных и об обработке этих данных. Работник может получить копию любой записи, содержащей его персональные данные, за исключением случаев, предусмотренных федеральным законом.</li>
                <li>Работник может получить доступ к медицинской документации, отражающей состояние его здоровья, с помощью медицинского работника по его выбору.</li>
                <li>Работник может определить представителя для защиты его персональных данных.</li>
                <li>Работник может требовать исключить или исправить свои неверные или неполные персональные данные, а также данные, обработанные с нарушением требований ТК РФ, ФЗ «О персональных данных» или иного федерального закона. При отказе Оператора исключить или исправить персональные данные работника он может заявить в письменной форме о своем несогласии и обосновать такое несогласие. Работник может дополнить персональные данные оценочного характера заявлением, выражающим его собственную точку зрения.</li>
                <li>Работник может требовать известить всех лиц, которым ранее были сообщены его неверные или неполные персональные данные, обо всех произведенных в них исключениях, исправлениях или дополнениях.</li>
                <li>Работник может обжаловать в суд любые неправомерные действия или бездействие Оператора при обработке и защите его персональных данных.</li>
              </ol>
          </li>

          <li><h2>Обработка персональных данных клиентов</h2>
              <ol>
                <li>Оператор обрабатывает персональные данные клиентов в рамках правоотношений с Оператором, урегулированных частью второй Гражданского Кодекса Российской Федерации от 26 января 1996 г. № 14-ФЗ, (далее — клиентов).</li>
                <li>Оператор обрабатывает персональные данные клиентов в целях соблюдения норм законодательства РФ, а также с целью: — заключать и выполнять обязательства по договорам с клиентами.</li>
                <li>Оператор обрабатывает персональные данные клиентов с их согласия, предоставляемого на срок действия заключенных с ними договоров. В случаях, предусмотренных ФЗ «О персональных данных», согласие предоставляется в письменном виде. В иных случаях согласие считается полученным при заключении договора или при совершении конклюдентных действий.</li>
                <li>Оператор обрабатывает персональные данные клиентов в течение сроков действия заключенных с ними договоров. Оператор может обрабатывать персональные данные клиентов после окончания сроков действия заключенных с ними договоров в течение срока, установленного п. 5 ч. 3 ст. 24 части первой НК РФ, ч. 1 ст. 29 ФЗ «О бухгалтерском учёте» и иными нормативными правовыми актами.</li>
                <li>Оператор обрабатывает специальные категории персональных данных несовершеннолетних клиентов с письменного согласия их законных представителей на основании ч. 1 ст. 9, п. 1 ч. 2 ст. 10 ФЗ «О персональных данных».</li>
                <li>Оператор обрабатывает следующие персональные данные клиентов:
                  <ul>
                    <li>Фамилия, имя, отчество;</li>
                    <li>Тип, серия и номер документа, удостоверяющего личность;</li>
                    <li>Дата выдачи документа, удостоверяющего личность, и информация о выдавшем его органе;</li>
                    <li>Адрес;</li>
                    <li>Адрес электронной почты;</li>
                    <li>Доходы;</li>
                    <li>Должность;</li>
                    <li>Год рождения;</li>
                    <li>Месяц рождения;</li>
                    <li>Дата рождения;</li>
                    <li>Место рождения;</li>
                    <li>Идентификационный номер налогоплательщика;</li>
                    <li>Номер страхового свидетельства государственного пенсионного страхования;</li>
                    <li>Национальная принадлежность;</li>
                    <li>Профессия;</li>
                    <li>Страховые взносы на ОПС;</li>
                    <li>Страховые взносы на ОМС;</li>
                    <li>Налоговые вычеты;</li>
                    <li>Выход на пенсию;</li>
                    <li>Табельный номер;</li>
                    <li>Трудовой стаж.</li>
                  </ul>
                </li>
              </ol>
          </li>

          <li><h2>Сведения об обеспечении безопасности персональных данных</h2>
              <ol>
                <li>Оператор назначает ответственного за организацию обработки персональных данных для выполнения обязанностей, предусмотренных ФЗ «О персональных данных» и принятыми в соответствии с ним нормативными правовыми актами.</li>
                <li>Оператор применяет комплекс правовых, организационных и технических мер по обеспечению безопасности персональных данных для обеспечения конфиденциальности персональных данных и их защиты от неправомерных действий:
                  <ul>
                    <li>обеспечивает неограниченный доступ к Политике, копия которой размещена по адресу нахождения Оператора, а также может быть размещена на сайте Оператора (при его наличии);</li>
                    <li>во исполнение Политики утверждает и приводит в действие документ «Положение об обработке персональных данных» (далее — Положение) и иные локальные акты;</li>
                    <li>производит ознакомление работников с положениями законодательства о персональных данных, а также с Политикой и Положением;</li>
                    <li>осуществляет допуск работников к персональным данным, обрабатываемым в информационной системе Оператора, а также к их материальным носителям только для выполнения трудовых обязанностей;</li>
                    <li>устанавливает правила доступа к персональным данным, обрабатываемым в информационной системе Оператора, а также обеспечивает регистрацию и учёт всех действий с ними;</li>
                    <li>производит оценку вреда, который может быть причинен субъектам персональных данных в случае нарушения ФЗ «О персональных данных»;</li>
                    <li>производит определение угроз безопасности персональных данных при их обработке в информационной системе Оператора;</li>
                    <li>применяет организационные и технические меры и использует средства защиты информации, необходимые для достижения установленного уровня защищенности персональных данных;</li>
                    <li>осуществляет обнаружение фактов несанкционированного доступа к персональным данным и принимает меры по реагированию, включая восстановление персональных данных, модифицированных или уничтоженных вследствие несанкционированного доступа к ним;</li>
                    <li>производит оценку эффективности принимаемых мер по обеспечению безопасности персональных данных до ввода в эксплуатацию информационной системы Оператора; </li>
                    <li>осуществляет внутренний контроль соответствия обработки персональных данных ФЗ «О персональных данных», принятым в соответствии с ним нормативным правовым актам, требованиям к защите персональных данных, Политике, Положению и иным локальным актам, включающий контроль за принимаемыми мерами по обеспечению безопасности персональных данных и их уровня защищенности при обработке в информационной системе Оператора.</li>
                  </ul>
                </li>
              </ol>
          </li>

          <li><h2>Права субъектов персональных данных</h2>
              <ol>
                <li>Субъект персональных данных имеет право:
                  <ul>
                    <li>на получение персональных данных, относящихся к данному субъекту, и информации, касающейся их обработки;</li>
                    <li>на уточнение, блокирование или уничтожение его персональных данных в случае, если они являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки;</li>
                    <li>на отзыв данного им согласия на обработку персональных данных;</li>
                    <li>на защиту своих прав и законных интересов, в том числе на возмещение убытков и компенсацию морального вреда в судебном порядке;</li>
                    <li>на обжалование действий или бездействия Оператора в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке.</li>
                  </ul>
                </li>
                <li>Для реализации своих прав и законных интересов субъекты персональных данных имеют право обратиться к Оператору либо направить запрос лично или с помощью представителя. Запрос должен содержать сведения, указанные в ч. 3 ст. 14 ФЗ «О персональных данных».</li>
              </ol>
          </li>


        </ol>
      </div>
    </section>
  )
}