import st from'./Menu.module.css';
import { Navigation } from "./Navigation";
import { MobileNavigation } from "./MobileNavigation";

export const Menu = () => {
  return (
    <div className={st.menu}>
      <Navigation />
      <MobileNavigation />
    </div>
  )
}
