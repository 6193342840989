import st from'./AdvantageList.module.css';
import aos from 'aos';
import 'aos/dist/aos.css'
import { useEffect } from 'react';

const mainPath = 'imgs/advantageIcons/advantage';
const advantageList = [
  {id: 1, pathIcon: `${mainPath}1.jpg`, title: 'Дополним', text: 'В частности, разбавленное изрядной долей эмпатии, рациональное мышление в значительной степени обусловливает '},
  {id: 2, pathIcon: `${mainPath}2.jpg`, title: 'Подменим', text: 'В частности, разбавленное изрядной долей эмпатии, рациональное мышление в значительной степени обусловливает '},
  {id: 3, pathIcon: `${mainPath}3.jpg`, title: 'Усилим', text: 'В частности, разбавленное изрядной долей эмпатии, рациональное мышление в значительной степени обусловливает '}
]

export const AdvantageList = () => {
  useEffect(() => {
    aos.init({
      duration: 1000,
      easing: 'ease-in-out-back',
    }); 
  }, [])
  return (
    <section className={st.advantageList}> 
      {advantageList.map((item, index) => <AdvantageItem key={item.id} pathIcon={item.pathIcon} 
                                  title={item.title} text={item.text} delay={index * 100} />)}
    </section>
  )
}

export const AdvantageItem = (props) => {
  return (
    <div className={st.advantageItem}>
      <img data-aos="zoom-in" data-aos-delay={props.delay} src={props.pathIcon} alt={props.title}></img>
      <h3 data-aos="fade-up" data-aos-delay={props.delay}>{props.title}</h3>
      <p data-aos="fade-up" data-aos-delay={props.delay}>{props.text}</p>
    </div>
  )
}