import { ContactForm } from '../ContactForm/ContactForm';
import st from'./ContactsBlock.module.css';
import aos from 'aos';
import 'aos/dist/aos.css'
import cn from 'classnames';
import { useEffect } from 'react';

export const ContactsBlock = (props) => {
  return (
    <section className={st.сontactsBlock} id="contacts">
      <div className="container">
        <h2>Контакты</h2>
        <ContactsContent />
      </div>
    </section>
  )
}

export const ContactsContent = () => {
  useEffect(() => {
    aos.init(); 
  }, [])
  return (
    <div className={st.сontactsWrap}>
      <div className={st.сontactsInfo}>
        <div className={st.сontactsAddress}>
          <address><a title="Нажмите, чтобы посмотреть адрес на Google картах" target="_blank" rel="noopener noreferrer" href="https://www.google.ru/maps/place/%D0%91%D0%B8%D0%B7%D0%BD%D0%B5%D1%81-%D1%86%D0%B5%D0%BD%D1%82%D1%80+%22SAS%22,+%D1%83%D0%BB.+%D0%9C%D0%BE%D0%BD%D1%82%D0%B0%D0%B6%D0%BD%D0%B8%D0%BA%D0%BE%D0%B2,+1+%D0%BA%D0%BE%D1%80%D0%BF%D1%83%D1%81+4,+%D0%9A%D1%80%D0%B0%D1%81%D0%BD%D0%BE%D0%B4%D0%B0%D1%80,+%D0%9A%D1%80%D0%B0%D1%81%D0%BD%D0%BE%D0%B4%D0%B0%D1%80%D1%81%D0%BA%D0%B8%D0%B9+%D0%BA%D1%80%D0%B0%D0%B9,+350051/@45.0596495,38.9624761,19.5z/data=!4m5!3m4!1s0x40f04f603aadff43:0x2b02b004f652224f!8m2!3d45.0595538!4d38.9626948?hl=ru">Краснодар, ул. Монтажников, 1/4</a></address>
          <address><a title="Нажмите, чтобы посмотреть адрес на Google картах" target="_blank" rel="noopener noreferrer" href="https://www.google.ru/maps/place/%D0%91%D0%B8%D0%B7%D0%BD%D0%B5%D1%81-%D1%86%D0%B5%D0%BD%D1%82%D1%80+%22SAS%22,+%D1%83%D0%BB.+%D0%9C%D0%BE%D0%BD%D1%82%D0%B0%D0%B6%D0%BD%D0%B8%D0%BA%D0%BE%D0%B2,+1+%D0%BA%D0%BE%D1%80%D0%BF%D1%83%D1%81+4,+%D0%9A%D1%80%D0%B0%D1%81%D0%BD%D0%BE%D0%B4%D0%B0%D1%80,+%D0%9A%D1%80%D0%B0%D1%81%D0%BD%D0%BE%D0%B4%D0%B0%D1%80%D1%81%D0%BA%D0%B8%D0%B9+%D0%BA%D1%80%D0%B0%D0%B9,+350051/@45.0596495,38.9624761,19.5z/data=!4m5!3m4!1s0x40f04f603aadff43:0x2b02b004f652224f!8m2!3d45.0595538!4d38.9626948?hl=ru">Москва, ул. Новокузнецкая, 4/12</a></address>
        </div>
        <a className={cn({[st.сontactsBigLink]: true, [st.bottomLine]: true})} href="mailto:teh2@adverbs.ru">teh2@adverbs.ru</a>
        <span>отправить ТЗ  или бриф</span>
        <a className={cn({[st.сontactsBigLink]: true, [st.bottomLine]: true, [st.telegramLink]: true})} href="https://telegram.im/@IvanKartyshev" target="_blank" rel="noopener noreferrer">Иван Картышев</a>
        <span>назначить собеседования и очертить задачи</span>
        <a className={st.сontactsBigLink} href="tel:+78612927372">(861) 292 73 72</a>
        <span>поговорить с аккаунтом</span>

        {/* <ContactsPhones /> */}
      </div>
      <ContactForm />
  </div>
  )
}

export const ContactsPhones = (props) => {
  console.log(props.colorBurger);
  return (
    <div className={st.сontactsPhones}>
      <a style={{color: props.colorBurger}} className={st.сontactsPhone} href="tel:+78612927372">(861) 292 73 72 <span style={{color: props.colorBurger}}>крд</span></a>
      <a style={{color: props.colorBurger}} className={st.сontactsPhone} href="tel:+74991136586">(499) 113-65-86 <span style={{color: props.colorBurger}}>мск</span></a>
    </div>
  )
}