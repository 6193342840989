import { NavLinks } from "./NavLinks"
import st from'./Menu.module.css';
import cn from 'classnames'
import { useEffect, useState } from "react";
import Logo from "../Logo/Logo";
import { ContactsPhones } from "../../ContactsBlock/ContactsBlock";

export const MobileNavigation = () => {
  const [isOpen, setIsOpen] = useState(false)
  const onOpened = () => {
    setIsOpen(!isOpen)
  } 
  const closeMobileMenu = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow")
    } else {
      document.body.classList.remove("overflow")
    }
  }, [isOpen])

  return (
    <>
      <nav className={cn({[st.mobileNavigation]: true, [st.mobileNavigationActive]: isOpen})}>
        {isOpen && <NavLinks closeMobileMenu={closeMobileMenu}/>}
        {isOpen &&  <div className={st.logoBurger}><Logo closeMobileMenu={closeMobileMenu} logo="imgs/logo-wh.svg" color="#FFFFFF"/></div>}
        {isOpen && <div className={st.сontactsPhones}><ContactsPhones colorBurger="#fff"/></div>}
      </nav>
      <Burger isOpen={isOpen} onOpened={onOpened}/>
    </>
  )
}

export const Burger = (props) => {
  return (
    <div  className={st.btnBurger} onClick={props.onOpened}>
      <span className={cn(st.burger,  {[st.burgerActive]: props.isOpen})}></span>
    </div>
  )
}