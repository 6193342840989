import { NavLink } from "react-router-dom"
import st from'./NotFound.module.css';
import Cat from '../../../imgs/cat.png'
import ScrollToTop from "../ScrollToTop/ScrollToTop";

export const NotFound = () => {
  return (
    <section className={st.errorPage}>
      <ScrollToTop/>
      <div className="container">
        <div className={st.errorWrap}>
          <span>404</span>
          <p>К сожалению, такой страницы нет :&nbsp;( </p>
          <img src={Cat} alt="Грустный кот"/>
          <p>Не грусти, ведь мы всегда тебя ждем <NavLink className={st.goHome} to="/">тут!</NavLink> </p>
        </div>
      </div>
    </section>
  )
}