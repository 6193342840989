import st from'./DownloadLink.module.css';
import Icon from '../../../imgs/arrow.svg'
import cn from 'classnames';

export const DownloadLink = (props) => {
  return (
    <div className={cn({[st.downloadWrap]: true})}>
      <a href={props.path} download>{props.text}</a>
      <div className={st.iconWrap}><img src={Icon} alt="Иконка скачивания" /> </div>
    </div>
  )
}