import st from'./AboutIvan.module.css';
import Avatar from '../../../imgs/avatar.jpg'
import { SocialItem } from '../../UI/SocialGroup/SocialGroup';

const StagesData = [
  {
    year: "2013",
    content: [
    'Собрал команду программистов и мы успешно работали на субподряде у крупной компании. Сам нанимал, обучал, развивал.'
    ]
  },
  {
    year: "2018",
    content: [
    'Работать на одного клиента комфортно, но опасно. Компания не пережила кризис 2018 и потянула нас за собой. Нашу душевную команду из мидлов и синьоров пришлось распустить. Мне уйти снова в найм и фриланс.'
    ]
  },
  {
    year: "2020",
    content: [
    'В 2020 году спрос на разработку вырос. Для меня это был шанс вернутся в бизнес.',
    'Объединился с агентством «Есть контакт!», у которых на тот момент уже был свой штат разработчиков.',
    'Возглавил отдел, стал нанимать, обучать, развивать — делать что делал всегда. Сейчас у нас в штате шесть программистов, два верстальщика, дизайнер, тестировщик и я как тимлид. Специализируемся на…. Такой командой разрабатываем и поддерживаем интернет-магазины….'
    ]
  },
]

export const AboutIvan = () => {
  return (
    <section className={st.aboutWrap}>
      <div className="container">
        <h2>Про Ивана</h2>
        <p className={st.aboutAccent + " " + st.aboutAccentMob}>&mdash; Привет, коллега. Надеюсь нам вместе работать, поэтому скажу пару слов о себе и команде.</p>
        <div className={st.aboutInner}>
          <div className={st.aboutBoard}>
            <img src={Avatar} alt={"Фотограция Ивана Картышева"}/>
            <div className={st.aboutBoardContent}>
              <p className={st.aboutName}>Иван Картышев</p>
              <div className={st.aboutRoles}>
                <span>Программист</span>
                <span>Предприниматель</span>
                <span>Отец троих детей </span>
              </div>
              <ul style={{display: 'flex'}}>
                <SocialItem href="https://t.me/estkontact" src="imgs/socials/tg-bl.svg" st={{marginRight: '10px'}}/>
                <SocialItem href="https://www.facebook.com/itadverbs/" src="imgs/socials/vk-bl.svg" />

              </ul>
              <span className={st.aboutSocialText}>Всегда на связи в телеграм и иногда в Фейсбук</span>
            </div>
          </div>
          <div className={st.aboutContent}>
            <p className={st.aboutAccent}>&mdash; Привет, коллега. Надеюсь нам вместе работать, поэтому скажу пару слов о себе и команде.</p>
            <div className={st.aboutContentCommon}>
              <p>Занимаюсь программированием с 18 лет.</p>
              <p>Работал тимлидом, программистом, системным администратором — понял, что в найме расти некуда и ушёл в свой бизнес.</p>
            </div>
            <div className={st.aboutStages}>
              {
                StagesData.map(stage =>  <Stage year={stage.year} content={stage.content}/> )
              }
            </div>
            
          </div>
        </div>
        <p className={st.aboutAccent} style={{textAlign: 'center'}}>Я о себе рассказал, буду рад знакомству и новым задачам</p>
      </div>
    </section>
  )
}

export const Stage = (props) => {
  return (
   <div className={st.aboutStage}>
     <div className={st.aboutStageYear}>{props.year}</div>
     <div className={st.aboutStageText}>
     {
       props.content.map(sentence  => <p>{sentence}</p>)
     }
     </div>
   </div>
  )
}