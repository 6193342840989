import st from '../Case.module.css';
import Slider from "react-slick";
import { useEffect, useState } from 'react';
import { ContactsBlock } from '../../../ContactsBlock/ContactsBlock';
import { NavLink, useLocation } from 'react-router-dom';
import { cases } from '../../Cases';
import { StackTag } from '../../../StackBlock/StackBlock';
import ScrollToTop from '../../../UI/ScrollToTop/ScrollToTop';

export const Case1 = (props) => {
  const settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      // {
      //   breakpoint: 900,
      //   settings: {
      //     slidesToShow: 2,
      //     slidesToScroll: 1,
      //   }
      // },
      // {
      //   breakpoint: 600,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //   }
      // }
    ]
  };

  const location = useLocation();
  let [dataCase, setDataCase] = useState({});

  
  useEffect(() => {
    let currentPage = location.pathname.replace("/cases/", "");
    let data = cases.filter(item => item.id === +currentPage);
    setDataCase(data[0]);

  
  }, [location]);

  const {id, title, tags, slider, aims, results} = dataCase;
  console.log('tags: ', tags);

  let stackTagsElements1= [];

  if (!!tags) {
    Object.keys(tags).forEach(function(key) {
      stackTagsElements1 = stackTagsElements1.concat(this[key].map(tag => <StackTag category={key}  name={tag}/>))
    }, tags);
  }
  


  return (
    <>
    <section className={st.caseSection}>
      <ScrollToTop/>
      <div className="container">
        <div className={st.caseBanner}>
          <h1>{title}</h1>
          <div className={st.breadcrumbs}>
            <NavLink to="/">Главная</NavLink>
            <NavLink to="/cases">Кейсы</NavLink>
            <NavLink to={`/cases/${id}`}>{title}</NavLink>
          </div>
        </div>
        <div className={st.armsAndResults}>
          <div>
            <p>Цель</p>
            <ul className={st.armsList}>
              { !!aims && aims.map(item => <li>{item}</li>)}
            </ul>
          </div>
          <div>
            <p>Результат</p>
              <ul className={st.resultsList}>
                { !!results && results.map(item => <li>{item}</li>)}
              </ul>
          </div>
        </div>
        <div className="sliderCase">
          <Slider {...settings}>
            {!!slider && slider.map(item =>  <img src={item} alt="Изображение разработанного сайта"/>)}
          </Slider>
        </div>
        <div className={st.tagsList}>
          <p>Используемые стеки</p>
          {stackTagsElements1}
        </div>
      </div>
    </section>
    <ContactsBlock />
    </>
  )
}