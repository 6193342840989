import st from './BitrixBlock.module.css';
import Slider from "react-slick";
import './BitrixBlock-slick.css';

const bitrixData = [
  {
    id: 1,
    href: "http://marketplace.1c-bitrix.ru/",
    img: {
      src: "imgs/bitrixCases/bitrixCase1.jpg",
      alt: "Картинка продукта"
    },
    title: "Битрикс Onliner",
    text: "Модуль для выгрузки товаров из вашего Модуль для выгрузки товаров из вашего интернет-магазина на торговую площадку onliner.by"
  },
  {
    id: 2,
    href: "http://marketplace.1c-bitrix.ru/",
    img: {
      src: "imgs/bitrixCases/bitrixCase2.jpg",
      alt: "Картинка продукта"
    },
    title: "Экспорт товаров на сайт 1k.by",
    text: "Быстрый и удобный способ для экспорта ваших товаров на торговую площадку 1k.by"
  },
  {
    id: 3,
    href: "http://marketplace.1c-bitrix.ru/",
    img: {
      src: "imgs/bitrixCases/bitrixCase3.jpg",
      alt: "Картинка продукта"
    },
    title: "Комплексное свойство",
    text: "Модуль предназначен для хранения сразу нескольких разных типов значений в одном свойстве"
  }
]


export const BitrixBlock = () => {
  const settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
  return (
    <section className={st.bitrixBlock} id="bitrix-marketplace">
      <div className="container">
        <h2>Наши решения Битрикс MARKETPLACE</h2>
        <div className="bitrixList">

        <Slider {...settings}>
          {
            bitrixData.map(item => <BitrixCase key={item.id} href={item.href} src={item.img.src} 
              alt={item.img.alt} title={item.title} text={item.text}/>)
          }
        </Slider>
        </div>
        
      </div>
    </section>
  )
}

export const BitrixCase = (props) => {
  return (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      <img className={st.bitrixImg} src={props.src} alt={props.alt} />
      <h5 className={st.bitrixTitle}>{props.title}</h5>
      <p className={st.bitrixText}>{props.text}</p>
    </a>
  )
}