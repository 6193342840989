import { NavLink } from "react-router-dom"
import cn from 'classnames'
import st from'./Menu.module.css';
import { HashLink } from 'react-router-hash-link';

export const NavLinks = (props) => {
  return (
    <ul className={cn(st.menuList)}>
      <li onClick={props.closeMobileMenu} className={st.menuItem}>
        <HashLink smooth={true} className={cn(st.menuLink )} to="/#competency">Компетенции</HashLink>
      </li>
      <li onClick={props.closeMobileMenu} className={st.menuItem}>
        <NavLink activeClassName={st.active} className={cn(st.menuLink, st.hover )} to="/cases">Проекты</NavLink>
      </li>
      <li onClick={props.closeMobileMenu} className={st.menuItem}>
        <HashLink  smooth={true} className={st.menuLink} to="/#bitrix-marketplace">BitrixMarketplace</HashLink>
      </li>
      <li onClick={props.closeMobileMenu} className={st.menuItem}>
        <NavLink activeClassName={st.active} className={st.menuLink} to="/about-ivan">Про Ивана</NavLink>
      </li>
      <li onClick={props.closeMobileMenu} className={st.menuItem}>
        <HashLink  smooth={true} className={st.menuLink} to="/#contacts">Контакты</HashLink>
      </li>
  </ul>
  )
}
