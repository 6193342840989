import st from'./SocialGroup.module.css';

export const SocialGroup = () => {
  return (
    <ul className={st.socialGroup}>
      <SocialItem href="https://vk.com/adverbs" src="/imgs/socials/vk.svg" />
      <SocialItem href="https://www.facebook.com/itadverbs/" src="/imgs/socials/facebook.svg" />
      <SocialItem href="https://t.me/estkontact" src="/imgs/socials/telegram.svg" />
    </ul>
  )
}

export const SocialItem = (props) => {
  return (
    <li style={props.st} ><a href={props.href} target="_blank" rel="noopener noreferrer"><img className={st.socialIcon} src={props.src} alt="Иконка соц.сети"/></a></li>
  )
}